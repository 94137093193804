<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.roles')"
                :pgIcon="'bx bx-lock'"
                :refs="refs"
                :addNew="addNew">
            </Breadcrumb>


            <DataTable
                :refs="refs"
                :colspan=6
                :watchCountryId="watchCountryId"

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=false
                :HasExport=true
                :hasShowEntries=true

                :hasName=true
                :name="$t('view.role_name')"

                :hasCountNo=true
                :count_no="$t('view.users_no')"
                
                :hasDate=true
                :hasStatus=true
                :hasActions=true

                :actionable=true
                :sorDataTable=true

                v-on:hasAddNewPermission="handlePermissoinChange">
            </DataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    props: ['globalCountryId'],
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        DataTable: () => import('@/components/DataTable.vue')
    },
    data(){
        return {    
            //
            addNew: false,
            refs: 'roles',
            watchCountryId: this.$props.globalCountryId,
        }
    },
    watch: {
        globalCountryId: function(new_val){
            this.watchCountryId = new_val;
        }
    },
    mounted() {},
    created() {},
    methods: {
        handlePermissoinChange(event) {
            this.addNew = event;
        },
    },
}
</script>
